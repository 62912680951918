.workRightEditHeader{
    display: flex;
    width: 90%;
}
.workRightEditHeaderText{
    font-family: Ubuntu-M;
    font-size: 16px;
    margin: 0 20px;
    color: var(--text-primary);
    min-width: max-content
}

.workRightEditHeaderInput {
    background: var(--bg-primary);
    border: none;
    border-bottom: 1px solid var(--text-secondary);
    color: var(--text-secondary);
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
}
.workRightEditHeaderInputError {
    background: var(--bg-primary);
    border: none;
    border-bottom: 1px solid #c12d2d;
    color: #c12d2d;
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
}

.workRightEditHeaderInputError::-webkit-input-placeholder {
    color:    #c12d2d;
}
.workRightEditHeaderInputError:-moz-placeholder {
    color:    #c12d2d;
    opacity:  1;
}
.workRightEditHeaderInputError::-moz-placeholder {
    color:    #c12d2d;
    opacity:  1;
}
.workRightEditHeaderInputError:-ms-input-placeholder {
    color:    #c12d2d;
}
.workRightEditHeaderInputError::-ms-input-placeholder {
    color:    #c12d2d;
}
.workRightEditHeaderInputError::placeholder {
    color:    #c12d2d;
}

.workRightEditHeaderError {
    position: absolute;
    bottom: -15px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    gap: 10px;
    color: #c12d2d;
    font-size: 13px;
    font-weight: 400;
}

.workRightInput {
    background: none;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid var(--text-secondary);
    padding-bottom: 5px;
    color: var(--text-secondary);
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
}
.workRightInputError {
    color: #c12d2d;
    border-bottom: 1px solid #c12d2d;
}
.workRightInputError::-webkit-input-placeholder {
    color:    #c12d2d;
}
.workRightInputError:-moz-placeholder {
    color:    #c12d2d;
    opacity:  1;
}
.workRightInputError::-moz-placeholder {
    color:    #c12d2d;
    opacity:  1;
}
.workRightInputError:-ms-input-placeholder {
    color:    #c12d2d;
}
.workRightInputError::-ms-input-placeholder {
    color:    #c12d2d;
}
.workRightInputError::placeholder {
    color:    #c12d2d;
}
.workRightInputErrorMessage {
    position: absolute;
    bottom: -15px;
    display: flex;
    padding-left: 10px;
    align-items: center;
    gap: 10px;
    color: #c12d2d;
    font-size: 13px;
    font-weight: 400;
}

.workRightInputBlock{
    position: relative;
    width: 100%;
}

.workRightEditBody {
    height: Calc(100% - 30px);
    margin: 10px;
    overflow: auto;
}
.workRightEditAddItemBlock{
    box-sizing: border-box;
    background: #f4f7fc;
    width: Calc(100% - 20px);
    display: flex;
    justify-content: center;
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
}

.workRightEditAddItem{
    font-family: Ubuntu-R;
    color: var(--text-secondary);
    border: 1px solid;
    display: flex;
    align-items: center;
    width: 200px;
    min-width: 110px;
    justify-content: space-evenly;
    border-radius: 100px;
    height: 36px;
    margin: 0 0 0 20px;
    cursor: pointer;
}
.workRightAddMenuSaveBlock{
    display: flex;
    gap: 20px;
    margin: 40px 0 0 10px;
}
.workRightEditItemInputBlock{
    width: 100%;
    padding: 10px 20px 10px 20px;
}
.workRightEditInputSelect{
    border: none;
    border-bottom: 1px solid;
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
    color: var(--text-secondary);
}
.workRightEditSetting{
    display: flex;
}
.workRightEditTextArea, .workRightEditTextAreaWitoutScrollbar {
    color: var(--text-secondary);
    resize: none;
    background: none;
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
    border: none;
}
.workRightEditTextArea::-webkit-scrollbar {
    cursor: pointer;
    width: 20px !important;
}
.workRightEditTextArea::-webkit-scrollbar-track {
    width: 10px !important;
}

.workRightEditTextArea::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #aebbbe;
    border: 6px solid transparent;
    background-clip: content-box;
}
.workRightEditTextAreaWitoutScrollbar::-webkit-scrollbar {
    display: none;
}
.workRightEditTableCollapseTR {
    padding: 0;
    margin: 0;
    height: 10px !important;
}
.workRightEditItem {
    position: relative;
    box-sizing: border-box;
    width: Calc(100% - 20px);
    border-radius: 10px;
    margin: 10px 0 10px 10px;
    padding: 20px;
    background: #f4f7fc;
}

.workRightEditItem:hover {
    padding: 18px;
    border: dashed 2px #b0c3c8;
}

.workRightEditItem:hover:after {
    content: '';
    position: absolute;
    border-right: dotted 4px #b0c3c8;
    top: 10px;
    bottom: 10px;
    left: 5px;
    cursor: grab;
    height: Calc(100% - 20px);
}

.workRightEditItem:hover > .workRightClose{
    opacity: 1;
}
.workRightEditItemActive {
    padding: 18px;
    position: relative;
    background: white !important;
    border: dashed 2px #b0c3c8;

    box-sizing: border-box;
    width: Calc(100% - 20px);
    border-radius: 10px;
    margin: 10px 0 10px 10px;
}

.workRightEditItemActive:after {
    position: absolute;
    border-right: dotted 4px #b0c3c8;
    content: '';
    top: 10px;
    bottom: 10px;
    left: 5px;
    cursor: grab;
    height: Calc(100% - 20px);
}

.workRightEditItemShort {
    position: relative;
    box-sizing: border-box;
    width: Calc(100% - 20px);
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    background: #f4f7fc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.workRightEditItemShortTitle {
    display: flex;
}

.workRightEditItemShortTitle > *:first-child {
    margin-right: 20px;
}

.workRightEditItemShort:hover, .workRightEditItemDraggin {
    padding: 18px;
    border: dashed 2px #b0c3c8;
}

.workRightEditItemShort:hover:after, .workRightEditItemDraggin:after {
    content: '';
    position: absolute;
    border-right: dotted 4px #b0c3c8;
    top: 10px;
    bottom: 10px;
    left: 5px;
    cursor: grab;
    height: Calc(100% - 20px);
}

.workRightEditItemShortButtons {

    position: relative;
    display: flex;
}

.manualArrow {
    width: 12px;
    height: 7px;
    -webkit-mask-repeat: no-repeat;
    cursor: pointer;
}
.manualArrowLeft {
    transform: rotate(-90deg);
}

.workRightCloseButton {
    position: absolute;
    top:50%;
    right: 0;

    transform: translateY(-50%) ;

    box-sizing: border-box;
    background: #e4eff2;
    width: 28px;
    height: 28px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    cursor: pointer;
    z-index: 201;
}

.workRightCloseButtonHidden {
    display: none;
}

.workRightCloseButton > img {
    width: 13px;
    height: 13px;
    color: #086c22;
}

.manualRow {
    display: grid;
    align-items: start;
    margin-bottom: 20px;
}

.workRightEditSwitchers .manualRow {
    grid-template-columns: 300px auto;
}

.workRightEditInputs .manualRow {
    grid-template-columns: 140px auto 60px;
}

.manualRowAlignCenter {
    align-items: center;
}
.manualRowAlignCenter:last-child {
    align-items: center;
    margin-bottom: 15px;
}

.toggleStructure {
    width: 60px;
    height: 55px;
    border-radius: 100%;

    margin-right: 20px;
    display: grid;
    place-items: center;
    z-index: 200;
    cursor: pointer;
}

.manualRowMono {
    grid-template-columns: Calc(100% - 30px) !important;
}

.manualSmallRow {
    display: flex;
    justify-content: end;
    height: 16px;
    margin-bottom: 0;
}
.deleteButton {
    width: 100%;
    display: flex;
    justify-content: end;
}

.manualLabel {

}