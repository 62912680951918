.range_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
}

.lane div:last-child {
    border-radius: 100%;
}

.rc-slider-track {
    background-color: blue !important;
}
