.container_file_loader {
    display: flex;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    flex-direction: column;
    /* height: 136px; */
    background: #f4f7fc;
    border-radius: 5px;
    border: 1px solid #b0c3c8;
    align-items: center;
    padding: 26px 20px;
}
.container_hover {
    box-shadow: 0 0 6px #00000021;
}

.title {
    color: #89999e;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
}

.subinfo {
    color: #89999e;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
}

.title img {
    margin-right: 10px;
}

.button {
    padding: 10px 0;
    border-radius: 100px;
    border: 1px solid var(--border-gray);
    font-family: Ubuntu-R;
    font-size: 11pt;
    margin: 24px 0 0 0;
    width: 191px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--border-gray);
    cursor: pointer;
    transition: 300ms;
}

.button:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(115, 128, 135, 0.5);
    transition: 300ms;
}

.error_block {
    display: flex;
}

.error_text {
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 6px;
}

.contain_list_file {
    font-family: Ubuntu-R;
    margin-top: 28px;
}

.list_file_block {
    display: flex;
    padding: 6px 2px;
}

.file {
    font-size: 12pt;
}

.list_file_button {
    border-radius: 100px;
    border: 1px solid;
    font-family: Ubuntu-R;
    font-size: 11pt;
    margin-top: 20px;
    height: 35px;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #29c39d;
    color: var(--bg-primary);
    cursor: pointer;
}

.list_file_button img {
    padding-right: 7px;
}

.delete_file {
    background: #e9ecf0;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    padding: 7px;
    margin: -5px 10px 0 10px;
    cursor: pointer;
}

.imagePreview, .image > div > div {
    width: 100%;
    max-height: 100%;
}

.image {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.pdfFile {
    opacity: .2;
}

.image > div {
    height: 50px;
    width: 50px;
    position: relative;
    margin-bottom: 40px;
}

.image > div:not(:last-child) {
    margin-right: 20px;
}

.image img:last-child {
    position: absolute;
    top: -20px;
    right: -30px;
    /*margin-right: 0;*/
}

.image > div {
    margin-right: 10px;
}
