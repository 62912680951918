.Work{
    width: 100%;
    height: calc(95% - 140px);
    display: flex;
    background: white;
}
.Work_resize{
    width: 3px;
    height: 100%;
    cursor: col-resize;
}
.Work_resize_block{
    height: calc(100% - 155px);
    width: 100%;
    position: absolute;
    cursor: col-resize;
}
