.barcodeContainer svg {
    max-width: 80px;
    max-height: 40px;

}

.barcodeContainer,
.catalogItems {
    width: 100%;
    min-width: 100px;
    height: 100%;
    margin-top: -3px;
    margin-right: 20px;
    display: flex;
}

.barcodeContainer {
    width: 100px;
    align-items: center;
}

.barcodeContainerWithInput {
    width: fit-content;
    height: 100%;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.w100 {
    width: 100%;
}

.container {
    height: 21px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.containerParent {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.containerParent:last-child {
    margin-bottom: 5px;
}

.inputBarcode {
    border: none;
    width: auto;
    border-bottom: 1px solid #000;
    font-size: 16px;
    color: var(--text-primary);
    padding: 0 0 5px 0;
    height: 21px;
    background: none;
}

.itemsContainer {
    width: 100%;
    margin-bottom: 10px;
    height: fit-content;
    max-width: 400px;
}
.buttons {
    display: flex;
    align-items: center;
    gap:10px;
    min-width: 50px;
    height: 25px;
}

.buttonClear, .buttonAdd {
    padding: 0;
    position: relative;
    height: 21px;
    width: 22px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b0e1df;
}
.buttonClear div {
    width: 11px;
    height: 11px;
}

.buttonAdd div {
    width: 13px;
    height: 13px;
}

.buttonAdd {
    background-color: var(--color-brand);
}

.withoutValue svg {
    opacity: 0;
}

.fromWidget {
    margin-top: -6px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: left;
}

.fromWidget > div > div:first-child > div:last-child input {
    max-width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.fromWidget > div > div:first-child {
    max-width: 100%;
    margin-right: 25px;
}

.fromWidget > div > div:first-child > div:last-child {
    margin-top: -5px;
}

.fromWidget > div {
    max-width: calc(100% - 60px);
}

.fromWidget > div > div:first-child > div:last-child {
    margin-right: 0;
    max-width: 206px;
}
/*
.fromWidget > div > div:last-child > div {
    margin-top: -10px;
}
*/
.fromWidget > div > div > div:first-child {
    min-width: 40%;
}

.workRightAddMenu_table {
    font-family: Ubuntu-R;
    width: 100%;
}
