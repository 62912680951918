.container {
    display: flex;
    gap: 10px;
}
.inputBarcode {
    border: none;
    border-bottom: 1px solid ;
    background: none;
    height: 20px;
    width: available;
}
