.workRightAddMenu {
    height: calc(91% - 95px);
    width: 100%;
    background: rgba(19, 57, 43, 0.54);
    position: absolute;
    top: 205px;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    overflow: hidden;
}

@keyframes moveRightToLeft {
    from {
        right: -100%;
    }

    to {
        right: 0;
    }
}

.workRightAddMenu_block {
    box-sizing: border-box;
    height: calc(100% - 30px);
    width: 600px;
    background: #DCE6E9;
    border-radius: 5px 0 0 5px;
    overflow: auto;
    margin: 10px 0 0 0;
    position: absolute;
    animation-name: moveRightToLeft;
    animation-duration: 600ms;
}
.workRightAddMenu_block::-webkit-scrollbar {
     width: 20px !important;
 }
.workRightAddMenu_block::-webkit-scrollbar-track {
    width: 10px !important;
}

.workRightAddMenu_block::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #aebbbe;
    border: 6px solid transparent;
    background-clip: content-box;
}

.workRightAddMenu_table {
    box-sizing: border-box;
    font-family: Ubuntu-R;
    width: 100%;
    padding: 30px;
}

.workRightAddMenu_table_row {
    margin-bottom: 15px;
    font-family: Ubuntu-M;
    display: grid;
    grid-template-columns: 40% 60%;
    letter-spacing: 0.04em;
}
.workRightAddMenu_table_row:last-child {
    margin-bottom: 0;
}
.workRightAddMenu_table_left {
    font-family: Ubuntu-M;
    display: flex;
    letter-spacing: 0.04em;
}

.workRightAddMenu_table_right {
    box-sizing: border-box;
    padding-bottom: 10px;
    color: var(--text-primary);
    max-width: 300px;
    width: 300px;
}

.workRightAddMenu_blockButton {
    box-sizing: border-box;
    display: flex;
    padding: 0 15px 15px;
}

.workRightAddMenuInput {
    background: #DCE6E9;
    border: none;
    border-bottom: 1px solid var(--border-gray);
    color: var(--text-primary);
    width: 100%;
    font-family: Ubuntu-R;
    font-size: 15px;
    resize: none;
    padding: 0 0 6px 10px;
}
.workRightAddMenuInput::-webkit-scrollbar {
    display: none;
}

.InputError {
    border-bottom: 1px solid #c12d2d;
}

.widgetSwitchVariants {
    display: flex;
    height: 100%;
    align-items: center;
}
