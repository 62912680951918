.addMenu_block{
    height: calc(100% - 35px);
    width: max-content;
    max-width: 360px;
    min-width: 275px;
    background: #DCE6E9;
    border-radius: 5px 0 0 5px;
    overflow: auto;
    margin: 10px 0 0 0;
    padding: 15px 10px 0 28px;
    color: var(--text-primary);
}
.title{

}
.block1{
    display: flex;
    justify-content: space-between;
}
.block1_text{
    margin: 11px 0 0 0;
}
.block1_items{
    display: flex;
    margin: 0 34px 0px 0px;
}
.block1_button{
    width: 26px;
    height: 26px;
    background: var(--color-brand);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    mask-size: contain;
    margin: 1px 7px 0 7px;
    cursor: pointer;
}
.block1_button_print{
    mask-image: url("../../../../svg/print-01.svg");
}
.block1_button_move{
    mask-image: url("../../../../svg/send-01.svg");
}
.block1_button_edit{
    mask-image: url("../../../../svg/edit-02.svg");
}
.block1_button_copy{
    mask-image: url("../../../../svg/copy-01.svg");
}
.block1_button_delete{
    mask-image: url("../../../../svg/delete-01.svg");
}
.icon{
    border-radius: 100%;
    background: #50B160;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    font-size: 25px;
    font-family: Ubuntu-R;
}
.block2{
    display: flex;
    margin: 17px 0 0 1px;
    align-items: center;
}
.number{
    font-size: 19pt;
    margin: 0 0 0 11px;
    font-family: Ubuntu-R;
}
.info_text{
    font-family: Ubuntu-R;
    font-size: 12pt;
    margin: 18px 0 0 0;
}
.info2_text{
    font-family: Ubuntu-R;
    font-size: 12pt;
    margin: 4px 0 0 0;
}
.block_barcode{
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: Ubuntu-R;
    font-size: 12pt;
    margin-top: 10px;
}

.block_barcode_text {
    margin-left: 10px
}
.info3{
    font-family: Ubuntu-R;
    font-size: 11pt;
    margin: 24px 0 8px 0;
}
.info3_text{
    margin: 0 0 4px 0;
}
.history{
    font-family: Ubuntu-R;
    font-size: 11pt;
    border-radius: 100px;
    border: 1px solid var(--border-gray);
    height: 34px;
    width: 195px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--border-gray);
    cursor: pointer;
}
.viewAll{
    font-family: Ubuntu-R;
    font-size: 11pt;
    border-radius: 100px;
    border: 1px solid var(--border-gray);
    height: 34px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--border-gray);
    cursor: pointer;
    margin-bottom: 30px;
}
.info_pacient{
    font-family: Ubuntu-R;
    font-size: 13pt;
    margin: 37px 0 0 0;
}
.info_pacient_block{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 300px;
    margin: 12px 0 11px 0;
}
.info_pacient_block2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 6px 0;
}
.info_pacient_text{
    font-family: Ubuntu-R;
    font-size: 11pt;
}
