.table{
    /*margin: 10px 70px 0 57px;*/
}

.header_text{
    font-family: Ubuntu-R;
    border-bottom: 1px solid var(--border-gray);
    padding-bottom: 10px;
    color: var(--text-primary);
}
.table_body{
    width: 100%;
    margin-top: 10px;
    text-align: center;
    display: grid;
}
.table_item{
    width: 42px;
    height: 42px;
    border: 3px solid var(--border-gray);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 10px 0px;
    z-index: 2;
    position: relative;
    background: var(--bg-primary);
    font-size: 18pt;
    font-family: 'Ubuntu-R';
    color: var(--text-primary);
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}



.table_item_ghost {
    position: absolute;
    top:0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    background: #d5d5d5;
}
.table_item:hover{
    border-color: rgb(74,134,96);
}
.table_border_green {

    border: 3px solid #c12d2d;
}

.table_item_delete{
    background: rgb(180,194,199);
    position: absolute;
    height: 40px;
    width: 28px;
    padding: 3px 0 0 0;
    transform: rotate(45deg);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    z-index: 1;
    transition: 0.2s all ease;
    top: 14px;
    left: calc(50% - 13px);
}
.table_item_delete_svg{
    -webkit-mask-image: url('../../../../svg/delete-01.svg');
    width: 15px;
    height: 16px;
    background: var(--bg-primary);
    -webkit-mask-repeat: no-repeat;
    margin: 0 0 2px 1px;
    cursor: pointer;
    transform: rotate(-45deg);
}

.table_item_select_svg {
    -webkit-mask-image: url('../../../../svg/button_on-01.svg');
    width: 15px;
    height: 16px;
    background: var(--bg-primary);
    -webkit-mask-repeat: no-repeat;
    margin: 2px 0 0 1px;
    cursor: pointer;
    transform: rotate(-45deg);

}

.table_item_green {
    background: var(--color-brand);
}

.table_item_block_body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.table_item_block{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.table_item_block:hover > .table_item_delete{
    left: calc(50% - -4px);
    top: -3px;
}
.table_item_edit{
    background: rgb(180,194,199);
    position: absolute;
    height: 40px;
    width: 28px;
    padding: 3px 0 3px 0px;
    transform: rotate(45deg);
    border-radius: 100px;
    justify-content: center;
    left: calc(50% - 13px);
    top: 12px;
    z-index: 1;
    align-items: flex-end;
    display: flex;
    transition: 0.2s all ease;
}
.table_item_edit_svg{
    -webkit-mask-image: url('../../../../svg/edit-01.svg');
    width: 15px;
    height: 16px;
    background: var(--bg-primary);
    -webkit-mask-repeat: no-repeat;
    margin: 0 0 2px 1px;
    cursor: pointer;
    transform: rotate(-45deg);
}
.table_item_block:hover > .table_item_edit{
    top: 28px;
    left: calc(50% - 30px);
}
.table_item_add{
    -webkit-mask-image: url('../../../../svg/add-01.svg');
    width: 20px;
    height: 20px;
    background: var(--bg-primary);
    -webkit-mask-repeat: no-repeat;
    position: absolute;
    left: calc(50% - 9px);
    top: 13px;
    z-index: 2;
    transition: 0.2s background-color ease;
}
.table_item:hover > .table_item_add_block{
    background: var(--color-brand);
}
.table_item_preload{
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 100%;
    z-index: 2;
}
.table_item_add_block{
    background-color: var(--bg-primary);
    border-radius: 100px;
    transition: 0.2s background-color ease;
    cursor: pointer;
    height: 35px;
    width: 35px;
}
/* Loader 1 */
.table_item_preload {
    -webkit-animation: loader-1-1 4.8s linear infinite;
    animation: loader-1-1 4.8s linear infinite;
}
@-webkit-keyframes loader-1-1 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-1-1 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.table_item_preload span {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    clip: rect(0, 20px, 20px, 16px);
    -webkit-animation: loader-1-2 1.2s linear infinite;
    animation: loader-1-2 1.2s linear infinite;
}
@-webkit-keyframes loader-1-2 {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(220deg); }
}
@keyframes loader-1-2 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(220deg); }
}
.loader-1 span::after {
    content: "";
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    clip: rect(0, 20px, 20px, 16px);
    border: 3px solid var(--color-brand);
    border-radius: 50%;
    -webkit-animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    animation: loader-1-3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes loader-1-3 {
    0%   { -webkit-transform: rotate(-140deg); }
    50%  { -webkit-transform: rotate(-160deg); }
    100% { -webkit-transform: rotate(140deg); }
}
@keyframes loader-1-3 {
    0%   { transform: rotate(-140deg); }
    50%  { transform: rotate(-160deg); }
    100% { transform: rotate(140deg); }
}
