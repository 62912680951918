.workRight{
    height: calc(100% - 15px);
}
.workRightTableItem_button_delete{
    mask-image: url("../../../../svg/delete-01.svg");
}
.workRightTableItem_button_copy{
    mask-image: url("../../../../svg/copy-01.svg");
}
.workRightTableItem_button_print{
    mask-image: url("../../../../svg/print-01.svg");
}
.workRightTableItem_button_edit{
    mask-image: url("../../../../svg/edit-01.svg");
}
.workRightTableItem_button_favorite{
    mask-image: url("../../../../svg/star2-01.svg");
}
.workRightTableItem_button_favorite_active{
    mask-image: url("../../../../svg/star-01.svg");
}

.workRightTableItem_save_delete{
    mask-image: url("../../../../svg/button_on-01.svg");
}

.workRightTableItem_cancel_delete{
    mask-image: url("../../../../svg/close-01.svg");
}

.workRightAddMenu_save{
    font-family: Ubuntu-R;
    background: var(--color-brand);
    color: white;
    display: flex;
    align-items: center;
    width: 130px;
    min-width: 130px;
    justify-content: space-evenly;
    border-radius: 100px;
    height: 36px;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-size: 15px;
}
.workRightAddMenu_cancel{
    font-family: Ubuntu-R;
    color: var(--text-secondary);
    border: 1px solid;
    display: flex;
    align-items: center;
    width: 130px;
    min-width: 130px;
    justify-content: space-evenly;
    border-radius: 100px;
    height: 34px;
    margin: 0 0 0 20px;
    cursor: pointer;
    font-size: 15px;
}
.workRightSwitch{
    position: relative;
    display: inline-block;
    width: 53px;
    height: 26px;
}
.workRightSwitch input {display:none;}
.workRightSlider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.workRightSlider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 1px;
    background-color: var(--bg-primary);
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .workRightSlider {
    background-color: var(--color-brand);
}

input:focus + .workRightSlider {
    box-shadow: 0 0 1px var(--color-brand);
}
input:checked + .workRightSlider:before {
    -webkit-transform: translateX(27px);
    -ms-transform: translateX(27px);
    transform: translateX(27px);
    background-image: url("../../../../svg/button_on-02.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.workRightSlider {
    border-radius: 34px;
}

.workRightSlider:before {
    border-radius: 50%;
}
.workRightCloseButton {
    box-sizing: border-box;
    background: #e4eff2;
    width: 28px;
    height: 28px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    cursor: pointer;
    transition: .2s;
}
.workRightCloseButton > img {
    width: 13px;
    height: 13px;
    color: #086c22;
}
.workRightClose{
    position: relative;
    margin: 5px;
    background: #f4f7fc;
    padding: 8px;
    border-radius: 100%;
    cursor: pointer;
    opacity: 0;
    transition: .2s;
    float: right;
}
.errorBlock{
    position: absolute;
    display: flex;
    margin: 30px 0px 0 142px;
}
.LoginErrorText2{
    color: #c12d2d;
    font-family: Ubuntu-R;
    font-size: 14px;
    letter-spacing: 0.04em;
    line-height: 1.7;
    margin-left: 5px;
}
.LoginInputError{
    border-bottom: 1px solid #c12d2d;
}
