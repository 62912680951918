.barcodeContainer svg {
    max-width: 80px;
    max-height: 40px;

}

.barcodeContainer {
    margin-top: -3px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    max-height: 40px;
}

.container {
    /*height: 40px;*/
    display: flex;
    align-items: center;
    margin-right: 20px;

    width: 314px;
}

.containerParent {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.containerParent:last-child {
    margin-bottom: 5px;
}

.inputBarcode {
    border: none;
    width: auto;
    border-bottom: 1px solid #000;
    font-size: 16px;
    color: var(--text-primary);
    padding: 0 0 5px 0;
    height: 21px;
}

.itemsContainer {
    width: 100%;
    margin-bottom: 10px;
    max-height: 240px;
    height: 240px;
    overflow-y: auto;
    max-width: 400px;
}
.buttons {
    display: flex;
    align-items: center;
    gap:10px
}

.buttonClear, .buttonAdd {
    padding: 0;
    position: relative;
    height: 22px;
    width: 23px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b0e1df;
}
.buttonClear div {
    width: 11px;
    height: 11px;
}

.buttonAdd div {
    width: 13px;
    height: 13px;
}

.buttonAdd {
    background-color: var(--color-brand);
}

/*.buttonClear::before, .buttonAdd::before,*/
/*.buttonClear::after, .buttonAdd::after {*/
/*    content: "";*/
/*    width: 15px;*/
/*    height: 2px;*/
/*    position: absolute;*/
/*    !*transform-origin: 0 0;*!*/
/*    !*transform: ;*!*/
/*    background: #13392b;*/
/*}*/

.withoutValue svg {
    opacity: 0;
}
