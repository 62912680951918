.container * {
    box-sizing: border-box;
}

.container {
    position: relative;
    width: 100%;
}

.swatch {
    padding:  0 5px 5px 5px;
    background: transparent;
    cursor: pointer;
}

.color {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.arrowIcon {
    position: absolute;
    right: -10px;
    top: calc(50% - 4px);
    transform: translate(0, -50%);
    transition: 0.1s;
    cursor: pointer;
}

.opened {
    transform: rotateZ(180deg) translateY(2px);
}