.WorkLeftHeader{
    display: flex;
    padding: 15px;
    align-items: center;
}
.WorkLeftHeader_favorite{
    margin-right: 10px;
    cursor: pointer;
    min-width: 20px;
}
.WorkLeftHeader_search{
    background: #f4f7fc;
    border-radius: 100px;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 400px;
}
.WorkLeftHeader_search_svg{
    height: 15px;
    padding: 0 6px 0 7px;
}
.WorkLeftHeader_search_input{
    background: #f4f7fc;
    width: 100%;
    border-radius: 0 100px 100px 0;
    border: none;
    font-size: 15px;
    font-family: Ubuntu-R;
}
.WorkLeftHeader_add{
    font-family: Ubuntu-R;
    background: var(--color-brand);
    color: white;
    display: flex;
    align-items: center;
    width: 110px;
    min-width: fit-content;
    padding: 0 10px;
    justify-content: space-evenly;
    border-radius: 100px;
    height: 36px;
    margin: 0 0 0 10px;
    cursor: pointer;
    letter-spacing: -0.03em;
}
.WorkLeftHeader_add_plus{
    mask-image: url("../../../svg/arrow-01.svg");
    background-color: white;
    height: 10px;
    width: 10px;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    padding: 0px 3px 0px 5px;
    margin: 0 0 0 4px;
}
.WorkLeftHeader_copy{
    cursor: pointer;
    margin-left: 10px;
}
.WorkLeftHeader_add_plus_Dropdown{
    position: absolute;
    top: 265px;
    background: #f4f7fc;
    border-radius: 10px;
    box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
    height: fit-content;
    overflow: auto;
    padding: 10px 0 12px;
    z-index: 5;
    width: 210px;
}
.WorkLeftHeader_add_plus_Dropdown_item{
    align-items: center;
    color: var(--text-primary);
    cursor: pointer;
    display: flex;
    font-family: Ubuntu-R;
    height: 30px;
    min-height: 30px;
    padding-left: 5px;
    position: relative;
    cursor: pointer;
}
.WorkLeftHeader_add_plus_Dropdown_item img {
    width: 25px;
    height: 16px;
    margin-right: 10px;
}
.WorkLeftHeader_add_plus_Dropdown_item:hover{
    background: #a2e3df;
}
.DropdownBlock{
    width: 100%;
    height: 100%;
    cursor: auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 4;
}
.WorkRightHeader_filter_printBlock{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.PrintBlock{
    position: absolute;
    border: 1px solid #50B160;
    border-radius: 5px;
    background: #f4f7fc;
    width: 219px;
    top: 200px;
    left: 190px;
    z-index: 1;
}
.PrintBlockHeader{
    padding: 10px 10px 2px 14px;
    font-family: Ubuntu-M;
}
.PrintBlockItem{
    cursor: pointer;
    padding: 7px 0px 7px 28px;
    font-family: Ubuntu-R;
}
.PrintBlockItem:hover{
    transition: 0.3s all ease;
    background-color: #a2e3df;
}
