.workSpace {
    width:100%;
    display:grid;
    grid-column-gap: 20px;
    grid-template-columns: auto 350px;
}
.samples {
    margin-left: 20px;
    margin-top: 20px;
    width: auto;
    overflow: scroll;
}

.sampleDeatilBox {
    position: absolute;
    top: 320px;
    right: 50px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
}

.sampleDeatilBox .row {
    display: flex;
    flex-direction: column;

}

.sampleTypescontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.sampleTypescontainer div{
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    border: 2px solid gray;
    cursor: pointer;
}
