.wrapper {
    padding-left: 56px;
    padding-top: 71px;
    padding-right: 67px;
    overflow: auto;
    display: block;
    height: calc(100% - 70px);
}

.infoItem, .storageAddress, .barcode {
    display: flex;
    position: relative;
    width: 100%;
}

.storageAddress {

}

.infoItem:nth-child(5), .infoItem:nth-child(6) {
    width: 200px;
}

.infoItemName {
    display: block;
    min-width: 126px;
    color: var(--text-primary);
    font-size: 17px;
    margin: 0 0 27px 0;
}

.infoItemValue {
    border: none;
    border-bottom: 1px solid #000;
    color: #000;
    margin-left: 10px;
    width: 100%;
    font-size: 16px;
    color: var(--text-primary);
    margin: 0 0 16px 18px;
    padding: 0 0 5px 0;
    height: 21px;
}

.barcode > span {
    margin-right: 14px;
}

.barcode {
    width: 100%;
}

.infoItemGraphicValue {
    border: none;
}

.arrowImg {
    position: absolute;
    margin: 8px 0 0 0;
    right: 0;
}

.storageAddress p {
    margin-right: 18px;
}

.storageAddress *:not(p) {
    cursor: pointer;
}

.storageAddress div span {
    margin-left: 0;
}

.infoItemIcon {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 20px;
}

.infoItemIcon span {
    min-height: 32px;
    margin-bottom: auto;
    margin-top: auto;
}

.infoItemIcon {
    max-width: 210px;
}

.infoItem span, .storageAddress span  {
    margin-left: 10px;
    font-size: 16px;
    color: var(--text-primary);
}

.barcode span {
    font-size: 16px;
    color: var(--text-primary);
}

.infoItemNameAbout{
    font-size: 17px;
    color: var(--text-primary);
    margin: 23px 0 0 1px;
}
.description{
    margin: -24px 0px 0px -1px;
}
.description textarea {
    font-family: Ubuntu-R;
    font-size: 14px;
    border: none;
    width: 100%;
    height: 40px;
    margin: 0px 0 20px 0px;
    color: var(--text-secondary);
    resize: none;
    display: inline-table;
}

.changeshistory {
    width: 192px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #858f94;
    border-radius: 20px;
    color: #858f94;
    cursor: pointer;
    margin-bottom: 41px;
    font-size: 11pt;
    margin-left: 4px;
    font-family: Ubuntu-R;
}

.functionalButtons {
    display: flex;
    gap: 18px;
    align-items: center;
}

.save {
    background: var(--color-brand);
    border: 1px solid var(--color-brand);
    color: var(--bg-primary);
    width: 130px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 20px;
    margin-left: 3px;
    font-size: 11pt;
    font-family: Ubuntu-R;
    cursor: pointer;
}

.cancel {
    border: 1px solid #858f94;
    color: #858f94;
    width: 110px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 20px;
    font-size: 11pt;
    font-family: Ubuntu-R;
    cursor: pointer;
}

.infoItemGraphicValue {
    position: relative;
    width: 32px;
    height: 32px;
    margin: -5px 0 0 16px;
}

.infoItemGraphicValue2 {
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--color-brand);
    margin: -7px 0 0px 17px;
}

.infoItemGraphicValueArrow {
    margin: 0 0 24px 11px;
    cursor: pointer;
}
.infoItemName_code{
    margin: 0 0 0 17px;
    font-family: Ubuntu-R;
    font-size: 12pt;
}
.colorDropdown{
    position: absolute;
    background: #f4f7fc;
    border-radius: 10px;
    box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
    //display: flex;
    //flex-direction: column;
    //max-height: 115px;
    //overflow: auto;
    //padding: 10px 10px 12px 10px;
    //z-index: 5;
    //left: 210px;
}
.colorDropdown2{
    position: absolute;
    border-radius: 10px;
    box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
    display: flex;
    width: 160px;
    padding: 5px 5px 5px 5px;
    z-index: 5;
    left: 30px;
    top: 39px;
    flex-wrap: wrap;
    border: 1px solid var(--border-gray);
    background: #f4f7fc;
}
.colorDropdownItem{
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    height: 30px;
    border-radius: 100px;
    cursor: pointer;
}
.iconDropdownItem{
    cursor: pointer;
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
    margin: 5px;
}
.treeLocation{
    position: absolute;
    background: #f4f7fc;
    border-radius: 10px;
    box-shadow: 0 27px 26px -5px rgb(34 60 80 / 20%);
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow: auto;
    padding: 10px 10px 12px 10px;
    z-index: 5;
    left: 144px;
    width: calc(100% - 164px);
    top: 28px;
    font-family: Ubuntu-R;
}
.dataErrorBlock{
    position: absolute;
    display: flex;
    margin: 26px 0px 0px 143px;
}
.errorInput{
    border-bottom: 1px solid #c12d2d;
}
